(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/panel/assets/javascripts/panel-extended.js') >= 0) return;  svs.modules.push('/components/ui/panel/assets/javascripts/panel-extended.js');
'use strict';

const PanelExtended = props => {
  const classNames = [];
  if (props.type === 'right') {
    classNames.push('panel-extended-right');
  } else {
    classNames.push('panel-extended-left-right');
  }
  if (props.className) {
    classNames.push(props.className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, props.children);
};
svs.ui = svs.ui || {};
svs.ui.ReactPanel = svs.ui.ReactPanel || {};
svs.ui.ReactPanel.PanelExtended = PanelExtended;

 })(window);