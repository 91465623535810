(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/panel/assets/javascripts/panel.js') >= 0) return;  svs.modules.push('/components/ui/panel/assets/javascripts/panel.js');
'use strict';

const Panel = props => {
  const classNames = ['panel'];
  if (props.branding) {
    classNames.push("panel-".concat(props.branding));
  } else {
    classNames.push('panel-default');
  }
  if (props.className) {
    classNames.push(props.className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, props.children);
};
svs.ui = svs.ui || {};
svs.ui.ReactPanel = svs.ui.ReactPanel || {};
svs.ui.ReactPanel.Panel = Panel;

 })(window);