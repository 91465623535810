(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/panel/assets/javascripts/panel-extended-aside.js') >= 0) return;  svs.modules.push('/components/ui/panel/assets/javascripts/panel-extended-aside.js');
'use strict';

const PanelExtendedAside = props => {
  const classNames = ['panel-extended-aside'];
  if (props.className) {
    classNames.push(props.className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, props.children);
};
svs.ui = svs.ui || {};
svs.ui.ReactPanel = svs.ui.ReactPanel || {};
svs.ui.ReactPanel.PanelExtendedAside = PanelExtendedAside;

 })(window);