(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/panel/assets/javascripts/panel-body.js') >= 0) return;  svs.modules.push('/components/ui/panel/assets/javascripts/panel-body.js');
'use strict';

const PanelBody = props => {
  const classNames = ['panel-body'];
  if (props.center) {
    classNames.push('panel-align-center');
  }
  if (props.className) {
    classNames.push(props.className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, props.children);
};
svs.ui = svs.ui || {};
svs.ui.ReactPanel = svs.ui.ReactPanel || {};
svs.ui.ReactPanel.PanelBody = PanelBody;

 })(window);