(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/panel/assets/javascripts/panel-extended-aside-box.js') >= 0) return;  svs.modules.push('/components/ui/panel/assets/javascripts/panel-extended-aside-box.js');
'use strict';

const PanelExtendedAsideBox = props => {
  const classNames = ['panel-extended-aside-box'];
  if (props.branding) {
    classNames.push("panel-extended-aside-box-".concat(props.branding));
  }
  if (props.className) {
    classNames.push(props.className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, props.children);
};
svs.ui = svs.ui || {};
svs.ui.ReactPanel = svs.ui.ReactPanel || {};
svs.ui.ReactPanel.PanelExtendedAsideBox = PanelExtendedAsideBox;

 })(window);